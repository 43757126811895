import Image from 'next/image';
import { clsx } from 'clsx';
import type { VariantIconName } from './icons';
import { spriteIcons, variantIcons, type IconName } from './icons';

export type IconProps = React.SVGProps<SVGSVGElement> & {
  color?: string;
  name: IconName | VariantIconName;
  colored?: boolean;
  monochromeDark?: boolean;
  lazy?: boolean;
};

export function Icon({
  name,
  color,
  colored = false,
  monochromeDark = false,
  className,
  ...props
}: IconProps): JSX.Element | null {
  if (name in variantIcons) {
    return (
      <VariantIcon
        className={className}
        color={color}
        colored={colored}
        icon={name as VariantIconName}
        monochromeDark={monochromeDark}
        {...props}
      />
    );
  }

  if (name in spriteIcons) {
    const file = spriteIcons[name as keyof typeof spriteIcons];
    const src = `${file.src}#${name}`;

    return (
      <SpriteIcon
        className={className}
        color={color}
        file={file.src}
        src={src}
        {...props}
      />
    );
  }

  // eslint-disable-next-line no-console
  console.warn(`Icon "${name}" not found in variantIcons or spriteIcons`);
  return null;
}

function SpriteIcon({
  color,
  src,
  className,
  file,
  ...props
}: {
  src: string;
  color?: string;
  file: string;
  className?: string;
} & React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <>
      <Image
        alt=""
        height={0}
        loading="eager"
        src={file}
        style={{ display: 'none', visibility: 'hidden' }}
        width={0}
      />
      <svg
        className={className}
        height={16}
        style={{ shapeRendering: 'unset', color, ...props.style }}
        width={16}
        {...props}
      >
        <use href={src} />
      </svg>
    </>
  );
}

function VariantIcon({
  icon,
  colored,
  monochromeDark,
  color,
  className,
  ...props
}: {
  color?: string;
  icon: VariantIconName;
  colored?: boolean;
  monochromeDark?: boolean;
  className?: string;
} & React.SVGProps<SVGSVGElement>): JSX.Element {
  const variantIcon = variantIcons[icon];
  const lightClassName = clsx('geist-hide-on-dark', className);
  const darkClassName = clsx('geist-hide-on-light', className);
  const lightSrc = `${variantIcon.light.src}#${icon}`;
  const darkSrc = `${variantIcon.dark.src}#${icon}`;

  return (
    <>
      {colored && variantIcon['color-light'] ? (
        <IconVariantImg
          {...variantIcon['color-light']}
          className={lightClassName}
          {...props}
        />
      ) : (
        <SpriteIcon
          className={lightClassName}
          color={color}
          file={variantIcon.light.src}
          src={lightSrc}
          {...props}
        />
      )}
      {colored && variantIcon['color-dark'] && !monochromeDark ? (
        <IconVariantImg
          {...variantIcon['color-dark']}
          className={darkClassName}
          {...props}
        />
      ) : (
        <SpriteIcon
          className={darkClassName}
          color={color}
          file={variantIcon.dark.src}
          src={darkSrc}
          {...props}
        />
      )}
    </>
  );
}

function IconVariantImg({
  src,
  className,
  ...props
}: {
  src: string;
  className?: string;
}): JSX.Element {
  return (
    <Image
      alt=""
      aria-hidden
      className={className}
      height={16}
      loading="eager"
      src={src}
      width={16}
      {...props}
    />
  );
}
