import type { ButtonLinkProps } from 'geist/components';
import { Stack } from 'geist/components';
import type { IconName } from 'geist/symbols';
import { Icon } from 'geist/symbols';
import { clsx } from 'clsx';
import type {
  Intent,
  VercelClickTypes,
} from '@vercel/site-analytics/vercel-client';
import { AnalyticsEvent } from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import type { ResponsiveText } from '../../lib/contentful/types';
import { ClientAnalyticsButtonLink } from '../site-analytics';
import styles from './cta-stack.module.css';

export interface MarketingCTALinkProps {
  type?: ButtonLinkProps['type'];
  shape?: ButtonLinkProps['shape'];
  trackClickName?: VercelClickTypes;
  trackClickValue?: string;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  className?: string;
  iconAsPrefix?: boolean;
  size?: ButtonLinkProps['size'];
  text: string | JSX.Element;
  responsiveText?: ResponsiveText;
  href: string;
  hoverStyle?: ButtonLinkProps['hoverStyle'];
  normalStyle?: ButtonLinkProps['normalStyle'];
  icon?: IconName;
  Component?: ButtonLinkProps['Component'];
  iconOnly?: boolean;
  intent?: Intent;
  style?: ButtonLinkProps['style'];
  tabIndex?: number;
  target?: ButtonLinkProps['target'];
  isLoading?: boolean;
}

export function MarketingCTALink({
  text,
  responsiveText,
  href,
  icon,
  type,
  trackClickName,
  trackClickValue,
  suffix,
  className,
  iconAsPrefix,
  size,
  hoverStyle,
  normalStyle,
  iconOnly,
  intent,
  ...props
}: MarketingCTALinkProps): JSX.Element {
  const { mobile, tablet, desktop } = useResponsiveText(text, responsiveText);

  return (
    <ClientAnalyticsButtonLink
      className={clsx(
        {
          [styles.prefixIcon]: iconAsPrefix,
        },
        className,
      )}
      data-track
      data-track-intent={intent}
      hoverStyle={hoverStyle}
      href={href}
      normalStyle={normalStyle}
      prefetch
      prefix={
        (suffix && icon) || (icon && iconAsPrefix) ? <Icon name={icon} /> : null
      }
      shadow={type === 'secondary'}
      shape={iconOnly ? 'circle' : 'rounded'}
      size={size ?? { sm: 'small', md: 'medium', lg: 'large' }}
      suffix={suffix}
      track={
        trackClickName
          ? {
              event: AnalyticsEvent.CLICK_EVENT,
              properties: {
                click_name: trackClickName,
                click_value: trackClickValue || text,
              },
            }
          : undefined
      }
      type={type}
      {...props}
    >
      <Stack align="center" direction="row" gap={2}>
        {iconOnly ? null : (
          <>
            {responsiveText ? (
              <>
                <span className={styles.mobile}>{mobile}</span>
                <span className={styles.tablet}>{tablet}</span>
                <span className={styles.desktop}>{desktop}</span>
              </>
            ) : (
              text
            )}
          </>
        )}
        {!suffix && icon && !iconAsPrefix ? <Icon name={icon} /> : null}
      </Stack>
    </ClientAnalyticsButtonLink>
  );
}

function useResponsiveText(
  fallbackText: string | JSX.Element,
  responsiveText?: ResponsiveText,
): {
  mobile: string | JSX.Element;
  tablet: string | JSX.Element;
  desktop: string | JSX.Element;
} {
  const { text: desktopText, mobileText, tabletText } = responsiveText ?? {};
  const mobile = mobileText ?? tabletText ?? desktopText ?? fallbackText;
  const tablet = tabletText ?? mobile;
  const desktop = desktopText ?? tablet;

  return { mobile, tablet, desktop };
}
